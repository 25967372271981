function instanaMonitoring(reportingUrl, apiKey) {
  (function (i, s, o, g, r, a, m) {
    i['InstanaEumObject'] = r;
    i[r] = i[r] || function () {
      (i[r].q = i[r].q || []).push(arguments)
    }, i[r].l = 1 * new Date();
    a = s.createElement(o),
      m = s.getElementsByTagName(o)[0];
    a.async = 1;
    a.src = g;
    m.parentNode.insertBefore(a, m);
  })(window, document, 'script', '//eum.instana.io/eum.min.js', 'ineum');
  ineum('reportingUrl', reportingUrl);
  ineum('apiKey', apiKey);
}
